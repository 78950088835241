import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps, AlertColor } from '@mui/lab/Alert';
import { useState, useEffect } from 'react';
import EventBus from '../../../core/EventBus';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toaster = () => {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [msgType, setMsgType] = useState<AlertColor>('success');

  const handleAlertClose = () => {
    setShowSuccessMsg(false);
  };

  useEffect(() => {
    EventBus.on('notify', (data) => {
      setShowSuccessMsg(true);
      setSuccessMsg(data.message);
      setMsgType(data.type as AlertColor);
    });
  }, []);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={showSuccessMsg}
      autoHideDuration={2000}
      onClose={handleAlertClose}
    >
      <div>
        <Alert severity={msgType} onClose={handleAlertClose}>
          {successMsg}
        </Alert>
      </div>
    </Snackbar>
  );
};

export default Toaster;
